import { PhantomSuiProvider, SuiTransactionInput } from '../../types';

export const signTransaction = async (
  params: SuiTransactionInput,
  provider: PhantomSuiProvider
): Promise<Uint8Array> => {
  try {
    const signature = await provider.signTransaction(params);
    return signature;
  } catch (error) {
    console.warn(error);
    throw new Error(error instanceof Error ? error.message : 'Failed to sign transaction');
  }
};

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Transaction } from '@mysten/sui/transactions';

import { getProvider, signMessage, signTransaction } from '../../utils/sui';
import { PhantomSuiProvider, ConnectedMethods, TLog } from '../../types';

import Sidebar from '../../components/Sidebar';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import Button from '../../components/Button';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import styled from 'styled-components';
import { SupportedSuiChainIds } from '../../constants/chains';

// =============================================================================
// Constants
// =============================================================================

const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
const sleep = (timeInMS: number) => new Promise((resolve) => setTimeout(resolve, timeInMS));

// =============================================================================
// Interfaces
// =============================================================================

interface Props {
  address: string | null;
  connectedMethods: ConnectedMethods[];
  handleConnect: () => Promise<void>;
  logs: TLog[];
  clearLogs: () => void;
  toggleLogs: () => void;
  logsVisibility: boolean;
}

// =============================================================================
// Styles
// =============================================================================

const ConnectButton = styled(Button)`
  margin-bottom: 15px;
`;

// =============================================================================
// Hooks
// =============================================================================

const useProps = (provider: PhantomSuiProvider): Props => {
  const [address, setAddress] = useState<string | null>(null);
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();

  /** Sign Message */
  const handleSignMessage = useCallback(async () => {
    if (!provider) return;

    try {
      const signature = await signMessage(message, provider, address.toString());
      createLog({
        status: 'success',
        method: 'signMessage',
        message: `Message signed: ${JSON.stringify(signature)}`,
      });
      toast.success('Message signed');
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signMessage',
        message: error.message,
      });
      toast.error('Failed to sign message');
    }
  }, [createLog, provider, address]);

  /** Sign Transaction */
  const handleSignTransaction = useCallback(async () => {
    if (!provider || !address) return;

    try {
      const tx = new Transaction();
      const [coin] = tx.splitCoins(tx.gas, [1]);
      tx.transferObjects([coin], address);

      const transactionParams = {
        transaction: await tx.toJSON(), // Replace with actual transaction
        address: address.toString(),
        networkID: SupportedSuiChainIds.SuiMainnet, // or your network ID
      };

      const signature = await signTransaction(transactionParams, provider);
      createLog({
        status: 'success',
        method: 'signTransaction',
        message: `Transaction signed: ${JSON.stringify(signature)}`,
      });
      toast.success('Transaction signed');
    } catch (error) {
      createLog({
        status: 'error',
        method: 'signTransaction',
        message: error.message,
      });
      toast.error('Failed to sign transaction');
    }
  }, [createLog, provider, address]);

  /** Connect */
  const handleConnect = useCallback(async () => {
    // Note: This is a placeholder. You'll need to implement the actual connect logic
    // based on Phantom's Sui wallet connection method
    try {
      const account = await provider.requestAccount();
      setAddress(account.address);
      createLog({
        status: 'success',
        method: 'connect',
        message: 'Connected to Phantom Sui Wallet',
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'connect',
        message: error.message,
      });
    }
  }, [createLog, provider]);

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Sign Message',
        onClick: handleSignMessage,
        chainIds: [SupportedSuiChainIds.SuiMainnet],
      },
      {
        name: 'Sign Transaction',
        onClick: handleSignTransaction,
        chainIds: [SupportedSuiChainIds.SuiMainnet],
      },
    ];
  }, [handleSignMessage, handleSignTransaction]);

  return {
    address,
    connectedMethods,
    handleConnect,
    logs,
    clearLogs,
    toggleLogs,
    logsVisibility,
  };
};

// =============================================================================
// Stateless Component
// =============================================================================

export const App = ({ provider, children }: { provider: PhantomSuiProvider; children?: React.ReactNode }) => {
  const { address, connectedMethods, handleConnect, logs, clearLogs, logsVisibility, toggleLogs } = useProps(provider);

  return (
    <AppWrapper>
      <Sidebar logsVisibility={logsVisibility} toggleLogs={toggleLogs} activePath="/sui-sandbox">
        {children}
        {address ? (
          <ConnectedAs addresses={{ evm: null, solana: null, sui: address }} />
        ) : (
          <div>
            <ConnectButton onClick={handleConnect} data-testid="connect-sui">
              Connect to Phantom
            </ConnectButton>
            <TestId id="connect-sui" />
          </div>
        )}
        <ActionButtons connectedMethods={connectedMethods} connected={!!address} />
      </Sidebar>

      {logsVisibility && <Logs connected={!!address} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

// =============================================================================
// Main Component
// =============================================================================

const AppWithProviders = () => {
  const [provider, setProvider] = useState<PhantomSuiProvider | null>(null);

  useEffect(() => {
    async function init() {
      await sleep(300);
      const prov = getProvider();
      setProvider(prov);
    }

    init();
  }, []);

  return (
    <LogsProvider>
      <App provider={provider} />
    </LogsProvider>
  );
};

export default AppWithProviders;

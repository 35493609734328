/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React from 'react';
import { Buffer } from 'buffer';

import Sidebar from '../../components/Sidebar';

import { Web3ReactProvider, Web3ReactHooks, useWeb3React } from '@web3-react/core/latest';
import { Connector } from '@web3-react/types/latest';
import { Web3Provider } from '@ethersproject/providers';

import allConnections from './utils/connectors';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { toHex, parseEther } from 'viem';
import Button from '../../components/Button';
import { SupportedEVMChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
// =============================================================================
// Web3-React Connector Config
// =============================================================================
const connections: [Connector, Web3ReactHooks][] = allConnections.map(([connector, hooks]) => [connector, hooks]);

const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;

const App = () => {
  const { connector, hooks } = useWeb3React();
  const isActivating = hooks.useSelectedIsActivating(connector);
  const isActive = hooks.useSelectedIsActive(connector);
  const account = hooks.useSelectedAccount(connector);
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();

  const signMessage = async () => {
    const sig = await connector?.provider.request({
      method: 'personal_sign',
      params: [msg, account, message],
    });
    createLog({
      status: 'success',
      method: 'signMessage',
      message: `Successfully signed Message: ${sig}`,
    });
  };
  const sendTransaction = async () => {
    const provider = new Web3Provider((window?.phantom as any)?.ethereum);
    try {
      const signer = provider.getSigner();
      const tx = {
        gasLimit: toHex(100000),
        to: '0x0000000000000000000000000000000000000000',
        value: parseEther('0.000000000000000001'), // 1 wei
      };
      const pendingHash = await signer.sendTransaction(tx);
      createLog({
        status: 'info',
        method: 'eth_sendTransaction',
        message: `sending TX: ${pendingHash.hash}`,
      });
      createLog({
        status: 'info',
        method: 'eth_sendTransaction',
        message: `pending....this could take up to 30 seconds`,
      });
      const finalizedHash = await pendingHash.wait(1);
      createLog({
        status: 'success',
        method: 'eth_sendTransaction',
        message: `successfully burned 1 wei of ETH ${finalizedHash.blockHash}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'eth_sendTransaction',
        message: error.message,
      });
    }
  };

  const handleToggleConnect = () => {
    if (isActive) {
      if (connector?.deactivate) {
        void connector.deactivate();
        createLog({
          status: 'success',
          method: 'disconnect',
          message: 'wallet disconnected',
        });
      } else {
        void connector.resetState();
        createLog({
          status: 'success',
          method: 'disconnect',
          message: 'wallet disconnected',
        });
      }
    } else if (!isActivating) {
      createLog({
        status: 'info',
        method: 'connect',
        message: 'connecting...',
      });
      Promise.resolve(connector.activate(1)) // 1 is Mainnet chainID
        .catch((e) => {
          connector.resetState();
          createLog({
            status: 'error',
            method: 'connect',
            message: e.message,
          });
        });
      createLog({
        status: 'success',
        method: 'connect',
        message: `successfully connected to app!`,
      });
    }
  };

  const connectedMethods = [
    {
      name: 'Sign message',
      chainIds: [SupportedEVMChainIds.EthereumMainnet],
      onClick: signMessage,
    },
    {
      name: 'Send transaction (burn 1 wei on Goerli)',
      chainIds: [SupportedEVMChainIds.EthereumMainnet],
      onClick: sendTransaction,
    },
    {
      name: 'Disconnect',
      chainIds: [SupportedEVMChainIds.EthereumMainnet],
      onClick: handleToggleConnect,
    },
  ];

  return (
    <AppWrapper>
      <Sidebar logsVisibility={logsVisibility} toggleLogs={toggleLogs} activePath="/web3-react-v8-sandbox">
        {!account && (
          <div>
            <Button onClick={handleToggleConnect} data-testid="connect">
              Connect to Phantom
            </Button>
            <TestId id="connect" />
          </div>
        )}
        {account && <ConnectedAs addresses={{ evm: account, solana: null }} />}
        <ActionButtons connected={!!account} connectedMethods={connectedMethods} />
      </Sidebar>
      {logsVisibility && <Logs connected={!!account} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

// =============================================================================
// Wrapper Component
// =============================================================================

const Wrapper = () => {
  return (
    <LogsProvider>
      <Web3ReactProvider connectors={connections}>
        <App />
      </Web3ReactProvider>
    </LogsProvider>
  );
};

export default Wrapper;

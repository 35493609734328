import { EthPreferencesProvider } from '../../hooks/useEthPreferences';
import { LogsProvider } from '../../hooks/useLogs';
import { getProvider } from '../../utils/evm';
import { App } from '../eth-sandbox/App';
import React, { useState } from 'react';
import { Web3Provider } from '../../types';
import { EmbeddedSDKLoader } from '../../components/EmbeddedSDKLoader';
import { EmbeddedContainer } from '../../components/EmbeddedSDKLoader/EmbeddedContainer';

const AppWithProviders = () => {
  const [provider, setProvider] = useState<Web3Provider | null>(null);

  return (
    <EthPreferencesProvider>
      <LogsProvider>
        <App provider={provider}>
          <EmbeddedSDKLoader
            onLoaded={() => {
              setProvider(getProvider());
            }}
          />

          <EmbeddedContainer />
        </App>
      </LogsProvider>
    </EthPreferencesProvider>
  );
};

export default AppWithProviders;

import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import { ERC721_TOKENS, TOKEN_LOCKER } from '../../constants/tokens';
import { SUPPORTED_CHAINS } from '../../constants/chains';

export async function approveAllERC721(
  chainId: string,
  provider: Web3Provider,
  approved = true
): Promise<TransactionResponse> {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const gasPrice = await provider.getGasPrice();
    const tokenAddress = ERC721_TOKENS[chainId];
    const contractApproveTo = TOKEN_LOCKER[chainId];

    if (!tokenAddress || !contractApproveTo) {
      throw new Error('Token address not found');
    }

    const hexChainId = SUPPORTED_CHAINS[chainId as any]?.hexChainId;
    if (!hexChainId) {
      throw new Error('Unsupported chain id');
    }
    // Encode the function signature and parameters
    const approveForAllFunctionSignature = utils.id('setApprovalForAll(address,bool)').slice(0, 10);
    const encodedParameters = utils.defaultAbiCoder.encode(['address', 'bool'], [contractApproveTo, approved]);
    const data = approveForAllFunctionSignature + encodedParameters.slice(2); // Remove the '0x' from encodedParameters

    const transactionParameters = {
      gasPrice, // customizable by user during confirmation.
      // gasLimit: utils.hexlify(100000),
      to: tokenAddress, // Required except during contract publications.
      from: address,
      value: 0, // Only required to send ether to the recipient from the initiating external account.
      data: data, // Call to mint() function
      chainId: hexChainId,
    };

    return signer.sendTransaction(transactionParameters);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
}

export async function revokeAllERC721(chainId: string, provider: Web3Provider): Promise<TransactionResponse> {
  return approveAllERC721(chainId, provider, false);
}

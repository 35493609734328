import React from 'react';
import Button from '../Button';

export function EmbeddedContainer() {
  return (
    <div
      id="embedded-container"
      style={{
        width: '360px',
        height: '600px',
        borderRadius: '12px',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <h2>Embedded Container</h2>
      </div>
    </div>
  );
}

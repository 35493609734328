import React from 'react';
import styled from 'styled-components';
import { PURPLE, WHITE } from '../../constants';
import { hexToRGB } from '../../utils';
import { SupportedChainIcons } from '../../constants/chains';

const Pre = styled.pre`
  margin-bottom: 5px;
`;

const AccountRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Badge = styled.div`
  margin: 0;
  padding: 10px;
  width: 100%;
  color: ${PURPLE};
  background-color: ${hexToRGB(PURPLE, 0.2)};
  font-size: 14px;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @media (max-width: 400px) {
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 320px) {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ::selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
  ::-moz-selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  > * {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ChainIcon = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.height};
  border-radius: 6px 0 0 6px;
  padding: 6px;
`;

const Label = styled.span`
  background: #f7941440;
  padding: 2px 6px;
  margin-left: 8px;
  border-radius: 8px;
`;

export function ConnectedAs({
  addresses,
  notConnected,
}: {
  addresses: {
    solana?: string | null;
    evm?: string | null;
    btcTaproot?: string | null;
    btcSegwit?: string | null;
    sui?: string | null;
  };
  notConnected?: React.ReactNode;
}) {
  return addresses.evm || addresses.solana || addresses.btcTaproot || addresses.btcSegwit ? (
    // connected
    <Container>
      <div>
        <Pre>Connected as</Pre>
        {addresses.evm && (
          <AccountRow>
            <ChainIcon src={SupportedChainIcons.Ethereum} height="36px" style={{ background: 'white' }} />
            <Badge data-testid={'evm-address'}>{addresses?.evm}</Badge>
          </AccountRow>
        )}

        {addresses.solana && (
          <AccountRow>
            <ChainIcon src={SupportedChainIcons.Solana} height="36px" style={{ background: 'black' }} />
            <Badge data-testid={'solana-address'}>{addresses?.solana}</Badge>
          </AccountRow>
        )}

        {addresses.btcTaproot && (
          <AccountRow>
            <ChainIcon src={SupportedChainIcons.Bitcoin} height="36px" style={{ background: '#f7941440' }} />
            <Badge>
              <span data-testid="btc-taproot-address">{addresses?.btcTaproot}</span>
              <Label>Taproot</Label>
            </Badge>
          </AccountRow>
        )}

        {addresses.btcSegwit && (
          <AccountRow>
            <ChainIcon src={SupportedChainIcons.Bitcoin} height="36px" style={{ background: '#f7941440' }} />
            <Badge>
              <span data-testid="btc-segwit-address">{addresses?.btcSegwit}</span>
              <Label>Segwit</Label>
            </Badge>
          </AccountRow>
        )}

        {addresses.sui && (
          <AccountRow>
            <ChainIcon src={SupportedChainIcons.Sui} height="36px" style={{ background: 'white' }} />
            <Badge>{addresses?.sui}</Badge>
          </AccountRow>
        )}
      </div>
    </Container>
  ) : (
    // not connected
    <>{notConnected ?? 'Not connected'}</>
  );
}

import { LogsProvider } from '../../hooks/useLogs';
import { App } from '../sui-sandbox/App';
import React, { useState } from 'react';
import { PhantomSuiProvider } from '../../types';

import { EmbeddedSDKLoader } from '../../components/EmbeddedSDKLoader';
import { EmbeddedContainer } from '../../components/EmbeddedSDKLoader/EmbeddedContainer';

const AppWithProviders = () => {
  const [provider, setProvider] = useState<PhantomSuiProvider | null>(null);

  return (
    <LogsProvider>
      <App provider={provider}>
        <div>
          <EmbeddedSDKLoader
            onLoaded={() => {
              // @ts-ignore - Phantom types will be updated
              setProvider((window.phantom as any)?.sui);
            }}
          />
        </div>

        <EmbeddedContainer />
      </App>
    </LogsProvider>
  );
};

export default AppWithProviders;

import { useState } from 'react';
import { createPhantom } from '@phantom/wallet-sdk';

import React from 'react';
import styled from 'styled-components';
import { GRAY, PURPLE } from '../../constants';
import { hexToRGB } from '../../utils';
import Button from '../Button';
import { CheckboxWithLabel } from '../CheckboxWithLabel';

const SDKButton = styled.button`
  display: block;
  color: ${GRAY};
  text-decoration: none;
  font-size: 14px;
  padding: 4px 6px;
  background-color: ${hexToRGB(PURPLE, 0.2)};
  border: none;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: white;
    font-weight: bold;
    background: ${hexToRGB(PURPLE, 0.5)};
  }

  &.selected {
    color: white;
    font-weight: bold;
    background: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const SDKButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const EmbeddedSDKLoader = ({ onLoaded }: { onLoaded: () => void }) => {
  const sdkURLs = [
    {
      name: 'Localhost',
      value: 'http://localhost:5173/dist/sdk.js',
    },
    {
      name: 'Staging',
      value: 'https://staging.embedded-wallet.phantom.dev/sdk.js',
    },
    {
      name: 'Production',
      value: 'https://embedded-wallet.phantom.app/sdk.js',
    },
  ];

  const [sdkURL, setSDKURL] = useState(sdkURLs[1]);
  const [embeddedContainer, setEmbeddedContainer] = useState(false);

  const onChangeSDK = (sdk) => {
    setSDKURL(sdk);
  };

  const [phantom, setPhantom] = useState<any | null>(null);

  const enableSDK = async () => {
    const phantom = await createPhantom({
      sdkURL: `${sdkURL.value}${embeddedContainer ? '?element=embedded-container' : ''}`,
    });
    console.log('phantom', phantom);
    setPhantom(phantom);

    setTimeout(() => {
      window.addEventListener('phantom#embedded#initialized', () => {
        setTimeout(() => {
          onLoaded();
        }, 300); // Wait for the provider to be set
      });
    }, 1000);
  };

  const handleFiatOnramp = () => {
    if (window.phantom && window.phantom.app) {
      window.phantom.app.buy({
        amount: 100,
        buy: 'solana:101/address:21AErpiB8uSb94oQKRcwuHqyHF93njAxBSbdUrpupump',
      });
    }
  };

  const handleGoToSwapper = () => {
    if (window.phantom && window.phantom.app) {
      window.phantom.app.swap({
        amount: '100000000',
        buy: 'solana:101/address:21AErpiB8uSb94oQKRcwuHqyHF93njAxBSbdUrpupump',
      });
    }
  };

  const handleShow = () => {
    if (phantom) {
      phantom.show();
    }
  };

  const handleHideWallet = () => {
    if (phantom) {
      phantom.hide();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginBottom: '12px' }}>
      {!phantom && (
        <>
          <SDKButtonContainer>
            {sdkURLs.map((sdk) => (
              <SDKButton
                key={sdk.value}
                className={sdkURL.value === sdk.value ? 'selected' : ''}
                onClick={() => onChangeSDK(sdk)}
              >
                {sdk.name}
              </SDKButton>
            ))}
          </SDKButtonContainer>
          <Button onClick={enableSDK}>Enable SDK</Button>
          <CheckboxWithLabel
            isEnabled={embeddedContainer}
            toggle={() => setEmbeddedContainer(!embeddedContainer)}
            label="Enable embedded container"
          />
        </>
      )}

      {phantom && (
        <>
          <Button onClick={handleFiatOnramp}>Go to fiat onramp</Button>
          <Button onClick={handleGoToSwapper}>Go to swapper</Button>
          <Button onClick={handleShow}>Show wallet</Button>
          <Button onClick={handleHideWallet}>Hide wallet</Button>
        </>
      )}
    </div>
  );
};

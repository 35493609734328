import { PhantomSuiProvider } from '../../types';

export const getProvider = (): PhantomSuiProvider => {
  if ('phantom' in window) {
    const provider = (window.phantom as any).sui;
    if (provider) {
      return provider;
    }
  }
  throw new Error('Please install Phantom wallet');
};

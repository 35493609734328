import { PhantomSuiProvider } from '../../types';

export const signMessage = async (
  message: string,
  provider: PhantomSuiProvider,
  address: string
): Promise<Uint8Array | string> => {
  try {
    const encodedMessage = new TextEncoder().encode(message);
    const signature = await provider.signMessage(encodedMessage, address);
    return signature;
  } catch (error) {
    console.warn(error);
    throw new Error(error instanceof Error ? error.message : 'Failed to sign message');
  }
};
